import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './TradeForm.module.scss';

const BuySellButtons = ({ orderId, instrument, handleInstrument }) => {
  if (!instrument) {
    return null;
  }

  return (
    <header>
      <div className={styles.buyAndSellButton}>
        <button
          disabled={orderId}
          type="button"
          onClick={() => handleInstrument({ ...instrument, tradeType: 'BUY' })}
          className={classNames({
            [styles.active]: instrument?.tradeType === 'BUY',
          })}
        >
          KJØP
        </button>
        <button
          disabled={orderId}
          type="button"
          onClick={() => handleInstrument({ ...instrument, tradeType: 'SELL' })}
          className={classNames({
            [styles.active]: instrument?.tradeType === 'SELL',
          })}
        >
          SELG
        </button>
        <div />
      </div>
    </header>
  );
};

BuySellButtons.propTypes = {
  handleInstrument: PropTypes.bool.isRequired,
  orderId: PropTypes.string,
  instrument: PropTypes.shape({}),
};

export default BuySellButtons;
