import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Trend } from '@oms/components';
import { formatNumber as number } from '@oms/utils';
import { getStocksSummary } from 'state/holdings/selectors';

const Summary = ({
  balance,
  balanceFailed,
  stockSummary: {
    marketValue,
    marketValueChange,
    marketValueChangePct,
    unrealizedProfitPct,
  } = {},
  className,
}) => (
  <dl className={className}>
    <div>
      <dt>Verdi</dt>
      <dd>
        <Trend value={marketValue}>{number(marketValue)}</Trend>
      </dd>
    </div>
    <div>
      <dt>I dag</dt>
      <dd>
        <Trend value={marketValueChange}>
          {number(marketValueChange)} ({number(marketValueChangePct)} %)
        </Trend>
      </dd>
    </div>
    <div>
      <dt>Ureal. gev./tap</dt>
      <dd>
        <Trend value={unrealizedProfitPct}>
          {number(unrealizedProfitPct)} %
        </Trend>
      </dd>
    </div>
    <div>
      <dt>Disponibelt</dt>
      <dd>
        {!balanceFailed && <Trend value={balance}>{number(balance)}</Trend>}
        {balanceFailed && <b>Banksystem utilgjengelig</b>}
      </dd>
    </div>
  </dl>
);

Summary.propTypes = {
  className: PropTypes.string,
  balance: PropTypes.number,
  stockSummary: PropTypes.shape({
    marketValue: PropTypes.number,
    unrealizedProfitPct: PropTypes.number,
    marketValueChange: PropTypes.number,
    marketValueChangePct: PropTypes.number,
  }),
};

const mapStateToProps = state => ({
  stockSummary: getStocksSummary(state),
});

export default connect(mapStateToProps)(Summary);
